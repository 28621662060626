export const MenuList = [
  {
    title: 'Kullanıcılar',
    classsChange: 'mm-collapse',
    role: ['user_read', 'role_read'],
    iconStyle: <i className="material-icons">group</i>,
    content: [
      {
        title: 'Kullanıcı Listesi',
        to: 'user',
        role: 'user_read',
      },
      {
        title: 'Rol Listesi',
        to: 'role',
        role: 'role_read',
      },
    ],
  },
  {
    title: 'Şirketler',
    classsChange: 'mm-collapse',
    role: ['company_read'],
    iconStyle: <i className="material-icons">apartment</i>,
    content: [
      {
        title: 'Şirket Listesi',
        to: 'company',
        role: 'company_read',
      },
    ],
  },
  {
    title: 'Kampanyalar',
    classsChange: 'mm-collapse',
    role: ['campaing_read', 'campaing_accept'],
    iconStyle: <i className="material-icons">campaign</i>,
    content: [
      {
        title: 'Liste',
        to: 'campaign',
        role: 'campaing_read',
      },
      {
        title: 'Onay ',
        to: 'campaign-accept',
        role: 'campaing_accept',
      },
    ],
  },
  {
    title: 'Kuponlar',
    classsChange: 'mm-collapse',
    role: ['promocode_read'],
    iconStyle: <i className="material-icons">campaign</i>,
    content: [
      {
        title: 'Liste',
        to: 'coupon',
        role: 'promocode_read',
      },
    ],
  },
];
