// BS
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'services/AxiosInstance';

const CampaingAccept = () => {
  const [data, setData] = useState();
  const [pageCount, setPageCount] = useState();
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  useEffect(() => {
    getList();
  }, [page]);

  const getStatusName = status => {
    switch (status) {
      case 'pending':
        return 'Onay Bekliyor';
      case 'approved':
        return 'Onaylandı';
      case 'declined':
        return 'İptal Edildi';
      default:
        return '';
    }
  };

  const getList = () => {
    axiosInstance
      .get(`campaing/list?limit=100&page=${page}&status=pending`)
      .then(res => {
        const resData = res.data.data?.map(item => {
          return {
            ...item,
            statusName: getStatusName(item?.status),
          };
        });
        setPageCount(res.data.page_count);
        setData(resData);
      })
      .catch(err => {});
  };
  const campaingUpdate = (data, status) => {
    data.status = status;
    console.log('data:', data);
    axiosInstance
      .put('campaing/update', data)
      .then(res => {
        getList();
      })
      .catch(error => {});
  };

  return (
    <div>
      <div className="row">
        {data?.map((item, index) => {
          return (
            <div
              onClick={() =>
                navigate('/edit-campaing', {
                  state: { id: item?.id, isAccept: true },
                })
              }
              className="col-xl-4 col-lg-12 col-sm-12">
              <div className="card overflow-hidden">
                <div className="text-center p-3 overlay-box">
                  <div className="profile-photo">
                    <img
                      src={item?.logo}
                      width="200"
                      height="200"
                      className="m-auto rounded-circle d-block"
                      alt=""
                    />
                  </div>
                  <h3 className="mt-3 mb-1 text-white">{item?.title}</h3>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Başlangıç Tarihi </span>{' '}
                    <strong className="text-muted">
                      {moment(item?.start_date).format('DD-MM-YYYY hh:mm')}
                    </strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Bitiş Tarihi</span>{' '}
                    <strong className="text-muted">
                      {moment(item?.end_date).format('DD-MM-YYYY hh:mm')}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          );
        })}
      </div>
      {/*  <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-header border-0 pb-0 d-block">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="card-title">Kampanya Onay Listesi</h4>
            </div>
          </div>
        <div className="card-body">
          <div className="table-responsive recentOrderTable">
            <table className="table verticle-middle table-responsive-md">
              <thead>
                <tr>
                  <th scope="col">Kampanya Başlığı</th>
                  <th scope="col">Başlangıç Tarihi</th>
                  <th scope="col">Bitiş Tarihi</th>
                  <th scope="col">Toplam Kod Sayısı</th>
                  <th scope="col">Oluşturan</th>
                  <th scope="col">Kampanya Durumu</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.title}</td>
                      <td>
                        {moment(item?.start_date).format('DD-MM-YYYY hh:mm')}
                      </td>
                      <td>
                        {moment(item?.end_date).format('DD-MM-YYYY hh:mm')}
                      </td>
                      <td>{item?.max_use}</td>
                      <td>{item?.creator}</td>
                      <td>
                        {item?.status === 'pending' && (
                          <span className="badge badge-rounded badge-warning">
                            {item?.statusName}
                          </span>
                        )}
                        {item?.status === 'approved' && (
                          <span className="badge badge-success badge-rounded">
                            {item?.statusName}
                          </span>
                        )}
                        {item?.status === 'declined' && (
                          <span className="badge badge-danger badge-rounded">
                            {item?.statusName}
                          </span>
                        )}
                      </td>
                      <td>
                        <Dropdown className="dropdown custom-dropdown mb-0">
                          <Dropdown.Toggle
                            className="btn sharp btn-primary tp-btn i-false"
                            data-toggle="dropdown">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              version="1.1">
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="12" cy="5" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="19" r="2" />
                              </g>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                            <Dropdown.Item
                              onClick={() =>
                                navigate('/edit-campaing', {
                                  state: { id: item?.id, isAccept: true },
                                })
                              }
                              className="dropdown-item">
                              Güncelle
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => campaingUpdate(item, 'approved')}
                              className="dropdown-item text-success">
                              Onayla
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => campaingUpdate(item, 'pending')}
                              className="dropdown-item text-warning">
                              Revize Et
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => campaingUpdate(item, 'declined')}
                              className="dropdown-item text-danger">
                              İptal Et
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
                </div>*/}
    </div>
  );
};

export default CampaingAccept;
