import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'services/AxiosInstance';

export const getCompanyList = createAsyncThunk(
  'campaing/getCompanyList',
  async props => {
    const response = await axiosInstance.get(`company/list`);
    return response?.data?.data;
  },
);

export const createCampaing = createAsyncThunk(
  'campaing/createCampaing',
  async props => {
    const response = await axiosInstance.post(`company/create`, props);
    return response?.data?.data;
  },
);

export const deleteCampaing = createAsyncThunk(
  'campaing/deleteCampaing',
  async props => {
    const response = await axiosInstance.delete(`campaing/delete?id=${props}`);
    return response?.data?.data;
  },
);
export const campaingSlice = createSlice({
  name: 'campaing',
  initialState: {
    companyData: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.companyData = action.payload;
    });
  },
});

export const {} = campaingSlice.actions;

export default campaingSlice.reducer;
