import axios from 'axios';
import swal from 'sweetalert';
import { store } from '../store/store';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(config => {
  const state = store.getState();
  const token = state.auth.auth.token;
  config.params = config.params || {};
  config.headers['token'] = token;
  config.headers['Content-Type'] = 'application/json';
  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    swal('Oops', `${error.response.data.message}`, 'error');
    return Promise.reject(error);
  },
);

export default axiosInstance;
