import { useContext } from 'react';

/// React router dom
import { Outlet, Route, Routes } from 'react-router-dom';

/// Css
import './chart.css';
import './index.css';
import './step.css';

/// Layout
import { ThemeContext } from '../context/ThemeContext';
import Footer from './layouts/Footer';
import Nav from './layouts/nav';
import ScrollToTop from './layouts/ScrollToTop';

//Home
import Home from './pages/Home';

//User
import AppProfile from './pages/Profile';
import AddUser from './pages/User/CreateUser';
import UserList from './pages/User/UserList';
//Campaign
import AddCampaing from './pages/Campaing/AddCampaign';
import CampaingAccept from './pages/Campaing/CampaignAccept';
import Campaing from './pages/Campaing/CampaignList';
import EditCampaing from './pages/Campaing/EditCampaign';
//Company
import Company from './pages/Company/CompanyList';

//Coupoun

import WithPermission from 'context/withPermissionHOC';
import CoupounList from './pages/Coupoun/CoupounList';
import Error400 from './pages/Error400';
import Error403 from './pages/Error403';
import Error404 from './pages/Error404';
import Error500 from './pages/Error500';
import Error503 from './pages/Error503';
import LockScreen from './pages/LockScreen';
import AddRole from './pages/Role/AddRole';
import EditRole from './pages/Role/EditRole';
import RoleList from './pages/Role/RoleList';

const Markup = () => {
  const allroutes = [
    // Dashboard
    { url: '', component: <Home />, role: 'public' },
    { url: 'dashboard', component: <Home />, role: 'public' },

    // User
    { url: 'add-user', component: <AddUser />, role: 'user_create' },
    { url: 'profile', component: <AppProfile />, role: 'user' },
    { url: 'user', component: <UserList />, role: 'user_read' },
    { url: 'role', component: <RoleList />, role: 'role_read' },
    { url: 'add-role', component: <AddRole />, role: 'role_create' },
    { url: 'edit-role', component: <EditRole />, role: 'role_update' },

    // Campaign
    { url: 'campaign', component: <Campaing />, role: 'campaing_read' },
    {
      url: 'add-campaign',
      component: <AddCampaing />,
      role: 'campaing_create',
    },
    {
      url: 'edit-campaing',
      component: <EditCampaing />,
      role: 'campaing_update',
    },
    {
      url: 'campaign-accept',
      component: <CampaingAccept />,
      role: 'campaing_update',
    },
    {
      url: '/company',
      component: <Company />,
      role: 'company_read',
    },

    // Coupoun
    { url: 'coupon', component: <CoupounList />, role: 'promocode_read' },
  ];

  const user = JSON.parse(localStorage.getItem('userDetails'));
  const permissions = ['company_update'];

  console.log('user:', user);
  return (
    <>
      <Routes>
        <Route path="page-lock-screen" element={<LockScreen />} />
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="*" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route element={<WithPermission permissions={[data?.role]} />}>
              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
            </Route>
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? 'menu-toggle' : ''}`}>
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
