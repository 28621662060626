import * as yup from 'yup';

export const campaignSchema = yup.object().shape({
  title: yup.string().required('Kampanya başlığı zorunludur'),
  appname: yup.string().required('Uygulama adı zorunludur'),
  start_date: yup.date().required('Başlangıç tarihi zorunludur'),
  end_date: yup.date().required('Bitiş tarihi zorunludur'),
  company_id: yup.number().required('Şirket bilgisi zorunludur'),
  description: yup.string().required('Kampanya detayı zorunludur'),
  requested_credentials: yup
    .string()
    .required('Requested Credentials zorunludur'),
  filter_json: yup.string().required('Kampanya detayı zorunludur'),
  logo: yup.string().required('Kampanya listeleme görsel URL zorunludur'),
  carousel_detail: yup
    .string()
    .required('Kampanya listeleme görselleri URL zorunludur'),
});
