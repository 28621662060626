import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'services/AxiosInstance';
import { campaignSchema } from './CampaignSchema';
const AddCampaing = () => {
  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    getCompanyList();
  }, []);

  const getCompanyList = () => {
    axiosInstance
      .get('company/list')
      .then(res => {
        setCompanyData(res.data.data);
      })
      .catch(err => {});
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(campaignSchema),
  });

  const user = JSON.parse(localStorage.getItem('userDetails'));
  const navigate = useNavigate();
  const onSubmit = data => {
    data.ta = 'test';
    data.nonce = 3;
    data.max_use = 1;
    data.use_period = 3;
    data.type = 1;
    data.status_check_url = 'test';
    data.get_proof_code_url = 'test';
    data.uniq_comp_key_def = 'test';
    data.is_valid = true;
    data.has_promocode = true;
    data.promo_order = 1;
    data.creator = user?.username;
    data.status = 'pending';
    data.is_chargeable = true;
    // Verileri işleme al
    axiosInstance
      .post('campaing/create', data)
      .then(res => {
        reset();
        navigate('/campaing');
      })
      .catch(error => {});
  };
  return (
    <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
      <div className="card p-5">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="card-title">Kampanya Oluştur</h4>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="form-label d-block">Kampanya Başlığı</label>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.title ? 'is-invalid' : ''
                }`}
                placeholder="Kampanya Başlığı"
                {...field}
              />
            )}
          />
          {errors.title && (
            <p className="invalid-feedback">{errors.title.message}</p>
          )}
          <label className="form-label d-block mt-3">Uygulama Adı</label>
          <Controller
            name="appname"
            control={control}
            render={({ field, value }) => (
              <select
                defaultValue={value}
                className={`form-control w-100 ${
                  errors.appname ? 'is-invalid' : ''
                }`}
                {...field}>
                <option> Uygulama Adı Seçiniz</option>
                <option value="zanahtar">Z-Anahtar</option>
                <option value="turkcell">Turkcell</option>
                <option value="edevlet">e-Devlet</option>
                <option value="promocell">promocell</option>
              </select>
            )}
          />
          {errors.appname && (
            <p className="invalid-feedback">{errors.appname.message}</p>
          )}

          <div className="form-check form-check-inline mt-3">
            <label className="form-check-label" for="inlineCheckbox1">
              Kampanya Herkes İçin mi?
            </label>

            <Controller
              name="is_for_everyone"
              control={control}
              render={({ field, value }) => (
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  {...field}
                  checked={field.value}
                />
              )}
            />
          </div>
          <div className="form-group  mt-3">
            <label for="datepicker">Başlangıç Tarihi:</label>
            <Controller
              name="start_date"
              control={control}
              render={({ field, value }) => (
                <input
                  defaultValue={moment(field.value).format('YYYY-MM-DD') || ''}
                  min={new Date().toJSON().slice(0, 10)}
                  onChange={e => {
                    field.onChange(e.target.value);
                  }}
                  type="date"
                  className={`form-control w-100 ${
                    errors.start_date ? 'is-invalid' : ''
                  }`}
                />
              )}
            />
            {errors.start_date && (
              <p className="invalid-feedback">{errors.start_date.message}</p>
            )}
          </div>
          <div className="form-group  mt-3">
            <label for="datepicker">Bitiş Tarihi:</label>
            <Controller
              name="end_date"
              control={control}
              render={({ field }) => {
                return (
                  <input
                    defaultValue={moment(field.value).format('YYYY-MM-DD')}
                    min={new Date().toJSON().slice(0, 10)}
                    onChange={e => {
                      field.onChange(e.target.value);
                    }}
                    type="date"
                    className={`form-control w-100 ${
                      errors.start_date ? 'is-invalid' : ''
                    }`}
                  />
                );
              }}
            />
            {errors.end_date && (
              <p className="invalid-feedback">{errors.end_date.message}</p>
            )}
          </div>
          <label className="form-label d-block mt-3">Şirket Bilgisi</label>
          <Controller
            name="company_id"
            control={control}
            render={({ field }) => (
              <select
                className={`form-control w-100 ${
                  errors.company_id ? 'is-invalid' : ''
                }`}
                {...field}>
                <option>Şirket Bilgisi Seçiniz</option>
                {companyData?.map((item, index) => {
                  return (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            )}
          />
          {errors.company_id && (
            <p className="invalid-feedback">{errors.company_id.message}</p>
          )}
          <div className="form-group mt-3">
            <label for="datepicker">Kampanya Detay Açıklama:</label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <textarea
                  className={`form-control h-100 ${
                    errors.description ? 'is-invalid' : ''
                  }`}
                  placeholder="Kampanya Detay Giriniz"
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className="invalid-feedback">{errors.description.message}</p>
            )}
          </div>
          <div className="form-group mt-3">
            <label for="datepicker">Kampanya Listeleme Görseli URL:</label>
            <Controller
              name="logo"
              control={control}
              render={({ field }) => (
                <textarea
                  className={`form-control h-100 ${
                    errors.logo ? 'is-invalid' : ''
                  }`}
                  placeholder="Kampanya Listeleme Görseli URL Giriniz"
                  {...field}
                />
              )}
            />
            {errors.logo && (
              <p className="invalid-feedback">{errors.logo.message}</p>
            )}
          </div>
          <div className="form-group mt-3">
            <label for="datepicker">Kampanya Listeleme Görselleri URL:</label>
            <Controller
              name="carousel_detail"
              control={control}
              render={({ field }) => (
                <textarea
                  className={`form-control h-100 ${
                    errors.carousel_detail ? 'is-invalid' : ''
                  }`}
                  placeholder="Kampanya Listeleme Görsellerini , ile ayırarak Giriniz"
                  {...field}
                />
              )}
            />
            {errors.carousel_detail && (
              <p className="invalid-feedback">
                {errors.carousel_detail.message}
              </p>
            )}
          </div>

          {/*   <label className="form-label d-block mt-3">
              Kampanya Listeme Görseli
            </label>

            <label className="form-label d-block mt-3">
              Kampanya Listeme Görselleri
            </label>
            <Dropzone
              multiple={false}
              autoUpload
              submitButtonDisabled
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              onSubmit={handleSubmit}
              accept="image/*,audio/*,video/*"
            />*/}

          <label className="form-label d-block mt-3">Request Credentials</label>
          <Controller
            name="requested_credentials"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.requested_credentials ? 'is-invalid' : ''
                }`}
                placeholder="Request Credentials Giriniz"
                {...field}
              />
            )}
          />
          {errors.requested_credentials && (
            <p className="invalid-feedback">
              {errors.requested_credentials.message}
            </p>
          )}

          <label className="form-label d-block mt-3">Filter Json</label>
          <Controller
            name="filter_json"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.filter_json ? 'is-invalid' : ''
                }`}
                placeholder="Filter Json Giriniz"
                {...field}
              />
            )}
          />
          {errors.filter_json && (
            <p className="invalid-feedback">{errors.filter_json.message}</p>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate('/campaing')}>
              Kapat
            </button>
            <button type="submit" className="btn btn-primary">
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddCampaing;
