import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import swal from 'sweetalert';

import 'react-dropzone-uploader/dist/styles.css';

import UseRoleShow from 'context/UseShow';
import { Dropdown, List } from 'jsx/components';
import { formatError } from 'services/AuthService';
import axiosInstance from 'services/AxiosInstance';

const RoleList = () => {
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    getList();
  }, [page, rowsPerPage]);

  const getList = () => {
    axiosInstance
      .get(`role/list?limit=${rowsPerPage}&page=${page + 1}`)
      .then(res => {
        setData(res.data.data);
        setPageCount(res.data.page_count);
        setTotalDataCount(res.data.data?.length * res.data.page_count);
      })
      .catch(err => {});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const navigate = useNavigate();

  const campaingDelete = id => {
    axiosInstance
      .delete(`role/delete?id=${id}`)
      .then(res => {
        getList();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const tableHeaders = [
    {
      key: 'name',
      label: 'Rol Adı',
    },
    {
      key: 'description',
      label: 'Açıklama',
    },
    {
      key: 'action',
      label: '',
    },
  ];

  const renderAction = item => {
    const actionItems = [
      {
        label: 'Güncelle',
        onClick: () =>
          navigate('/edit-role', {
            state: { id: item?.id },
          }),
        className: '',
      },
      {
        label: 'Sil',
        onClick: () => campaingDelete(item?.id),
        className: 'text-danger',
      },
    ];
    return <Dropdown items={actionItems} />;
  };

  const tableBody = () => {
    return data?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item?.name}</td>
          <td>{item?.description}</td>
          <td>{renderAction(item)}</td>
        </tr>
      );
    });
  };

  return (
    <List
      isCreate={UseRoleShow(['role_crate'])}
      title="Rol Listesi"
      body={tableBody}
      addLink="/add-role"
      headers={tableHeaders}
      pageCount={totalDataCount}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default RoleList;
