import { yupResolver } from '@hookform/resolvers/yup';
import UseRoleShow from 'context/UseShow';
import { List } from 'jsx/components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import 'react-dropzone-uploader/dist/styles.css';
import { Controller, useForm } from 'react-hook-form';
import { formatError } from 'services/AuthService';
import axiosInstance from 'services/AxiosInstance';
import swal from 'sweetalert';
import * as yup from 'yup';

const schema = yup.object().shape({
  code: yup.string().required('Promosyon Kod zorunludur'),
  promodef_id: yup.string().required('Promodef zorunludur'),
  expire_date: yup.date().required('Başlangıç tarihi zorunludur'),
  count: yup.string().required('Kupon Sayısı zorunludur'),
});
const multi_schema = yup.object().shape({
  prefix: yup.string().required('Promosyon Kod Prefix zorunludur'),
  promodef_id: yup.string().required('Promodef zorunludur'),
  expire_date: yup.date().required('Başlangıç tarihi zorunludur'),
  count: yup.string().required('Kupon Sayısı zorunludur'),
});
const CoupounList = () => {
  const [userData, setUserData] = useState();
  const [contactModal, setContactModal] = useState();
  const [edit, setEdit] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [companyData, setCompanyData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isMulti, setIsMulti] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isMulti ? multi_schema : schema),
  });

  useEffect(() => {
    getPromoList();
    getCompanyList();
  }, []);

  useEffect(() => {
    getPromoList();
  }, [page, rowsPerPage]);

  const getPromoList = () => {
    axiosInstance
      .get(`promocode/list?limit=${rowsPerPage}&page=${page + 1}`)
      .then(res => {
        setUserData(res.data.data);
        setPageCount(res.data.page_count);
        setTotalDataCount(res.data.data?.length * res.data.page_count);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const getCompanyList = () => {
    axiosInstance
      .get('campaing/list')
      .then(res => {
        setCompanyData(res.data.data);
        setPageCount(res.data.page_count);
      })
      .catch(err => {});
  };
  const userDelete = id => {
    axiosInstance
      .delete(`user/delete?id=${id}`)
      .then(res => {
        getPromoList();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const onSubmit = data => {
    data.is_valid = true;
    axiosInstance
      .post('promocode/multicreate', data)
      .then(res => {
        getPromoList();
        reset();
        setContactModal(false);
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const onEditModal = id => {
    setSelectedId(id);
    axiosInstance.get(`promocode/get?id=${id}`).then(res => {
      if (res.data.data.prefix && res.data.data.prefix.length > 0) {
        setValue('is_valid', res.data.data.is_valid);
        setValue('expire_date', res.data.data.expire_date);
        setValue('prefix', res.data.data.prefix);
        setValue('count', res.data.data.count);
        setIsMulti(true);
      } else {
        setValue('code', res.data.data.code);
        setValue('promodef_id', res.data.data.promodef_id);
        setValue('is_valid', res.data.data.is_valid);
        setValue('expire_date', res.data.data.expire_date);
        setValue('count', res.data.data.count);
        setIsMulti(false);
      }
      setContactModal(true);
      setEdit(true);
    });
  };

  const closeModal = () => {
    reset();
    setEdit(false);
    setContactModal(false);
    setSelectedId();
  };

  const onUpdate = data => {
    data.id = selectedId;
    axiosInstance
      .put('promocode/update', data)
      .then(res => {
        getPromoList();
        closeModal();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const tableHeaders = [
    {
      key: 'promo_name',
      label: 'Kampanya Adı',
    },
    {
      key: 'code',
      label: 'Kod',
    },
    {
      key: 'expire_date',
      label: 'Bitiş Tarihi',
    },
    {
      key: 'action',
      label: '',
    },
  ];

  const renderAction = item => {
    const actionItems = [
      {
        label: 'Güncelle',
        onClick: () => onEditModal(item?.id),
        className: '',
      },
      {
        label: 'Sil',
        onClick: () => userDelete(item?.id),
        className: 'text-danger',
      },
    ];
    return <Dropdown items={actionItems} />;
  };

  const tableBody = () => {
    return userData?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item?.promo_name}</td>
          <td>{item?.promo_code}</td>
          <td>{moment(item?.expire_date).format('DD-MM-YYYY HH:MM')}</td>
          <td>{renderAction(item)}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <List
        title="Kuponlar Listesi"
        isCreate={UseRoleShow(['promocode_create'])}
        body={tableBody}
        editModal
        onEditModal={() => setContactModal(true)}
        headers={tableHeaders}
        pageCount={totalDataCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Modal
        className="modal fade"
        id="exampleModal"
        centered
        show={contactModal}
        onHide={closeModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Yeni Promosyon Kodu Ekle
            </h5>
            <button
              onClick={closeModal}
              type="button"
              className="btn-close"></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(edit ? onUpdate : onSubmit)}>
              <div className="form-check form-check-inline mt-3">
                <label className="form-check-label" for="inlineCheckbox1">
                  Prefix Kullan
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  onChange={() => setIsMulti(!isMulti)}
                  checked={isMulti}
                />
              </div>
              <p>
                İstediğiniz özel kodları prefix vererek random oluşturmak için
                seçiniz.
              </p>
              <>
                {!isMulti ? (
                  <>
                    <label className="form-label d-block mt-3">Kod</label>
                    <Controller
                      name="code"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            errors.code ? 'is-invalid' : ''
                          }`}
                          placeholder="Kodu Giriniz"
                          {...field}
                        />
                      )}
                    />
                    {errors.code && (
                      <p className="invalid-feedback">{errors.code.message}</p>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    <label className="form-label d-block mt-3">Prefix</label>
                    <Controller
                      name="prefix"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            errors.prefix ? 'is-invalid' : ''
                          }`}
                          placeholder="Prefix Giriniz"
                          {...field}
                        />
                      )}
                    />
                    <p>
                      İstediğiniz özel kodları oluşturmak için bir prefix girin
                      (örneğin, "XYZ" gibi).
                    </p>
                    {errors.prefix && (
                      <p className="invalid-feedback">
                        {errors.prefix.message}
                      </p>
                    )}
                  </>
                )}
                <label className="form-label d-block mt-3">
                  Toplam Kod Sayısı
                </label>
                <Controller
                  name="count"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        errors.count ? 'is-invalid' : ''
                      }`}
                      placeholder="Toplam Kod Sayısı"
                      {...field}
                    />
                  )}
                />
                {errors.count && (
                  <p className="invalid-feedback">{errors.count.message}</p>
                )}
              </>
              <label className="form-label d-block mt-3">
                Kampanya Bilgisi
              </label>
              <Controller
                name="promodef_id"
                control={control}
                render={({ field }) => (
                  <select
                    className={`form-control w-100 ${
                      errors.promodef_id ? 'is-invalid' : ''
                    }`}
                    {...field}>
                    <option>Kampanya Seçiniz</option>
                    {companyData?.map((item, index) => {
                      return (
                        <option key={index} value={item?.id}>
                          {item?.title}
                        </option>
                      );
                    })}
                  </select>
                )}
              />
              {errors.promodef_id && (
                <p className="invalid-feedback">{errors.promodef_id.message}</p>
              )}
              <label for="datepicker" className="mt-3">
                Bitiş Tarihi
              </label>
              <Controller
                name="expire_date"
                control={control}
                render={({ field, value }) => (
                  <input
                    defaultValue={
                      moment(field.value).format('YYYY-MM-DD') || ''
                    }
                    min={new Date().toJSON().slice(0, 10)}
                    onChange={e => {
                      field.onChange(e.target.value);
                    }}
                    type="date"
                    className={`form-control w-100 ${
                      errors.expire_date ? 'is-invalid' : ''
                    }`}
                  />
                )}
              />
              {errors.expire_date && (
                <p className="invalid-feedback">{errors.expire_date.message}</p>
              )}
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Kaydet
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CoupounList;
