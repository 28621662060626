// BS
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { formatError } from 'services/AuthService';
import axiosInstance from 'services/AxiosInstance';
import swal from 'sweetalert';
import * as yup from 'yup';

const schema = yup.object().shape({
  username: yup.string().required('Username zorunludur'),
  email: yup.string().email('Email zorunludur').required('Email zorunludur'),
  password: yup.string().required('Şifre zorunludur'),
  company_id: yup.number().required('Şirket bilgisi zorunludur'),
  role_id: yup.string().required('Role zorunludur'),
});

const CreateUser = () => {
  const [contactModal, setContactModal] = useState(true);
  const [companyData, setCompanyData] = useState();
  const [roleData, setRoleData] = useState();

  useEffect(() => {
    getCompanyList();
    getRoleList();
  }, []);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getCompanyList = () => {
    axiosInstance
      .get('company/list')
      .then(res => {
        setCompanyData(res.data.data);
      })
      .catch(err => {});
  };

  const getRoleList = () => {
    axiosInstance
      .get('role/list')
      .then(res => {
        setRoleData(res.data.data);
      })
      .catch(err => {});
  };

  const onSubmit = data => {
    axiosInstance
      .post('user/create', data)
      .then(res => {
        reset();
        swal('Success', `${res.data.message}`, 'success');
        onHideModal();
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };
  let navigate = useNavigate();

  const onHideModal = () => {
    navigate('/user');
  };
  return (
    <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
      <div className="card p-5">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="card-title">Yeni Kullanıcı Ekle</h4>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="form-label d-block mt-3">Ad</label>
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.firstname ? 'is-invalid' : ''
                }`}
                placeholder="Ad Giriniz"
                {...field}
              />
            )}
          />
          {errors.firstname && (
            <p className="invalid-feedback">{errors.firstname.message}</p>
          )}
          <label className="form-label d-block mt-3">Soyad</label>
          <Controller
            name="surname"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.surname ? 'is-invalid' : ''
                }`}
                placeholder="Soyad Giriniz"
                {...field}
              />
            )}
          />
          {errors.surname && (
            <p className="invalid-feedback">{errors.surname.message}</p>
          )}
          <label className="form-label d-block mt-3">Kullanıcı Adı</label>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.username ? 'is-invalid' : ''
                }`}
                placeholder="Kullanıcı Adı Giriniz"
                {...field}
              />
            )}
          />
          {errors.username && (
            <p className="invalid-feedback">{errors.username.message}</p>
          )}
          <label className="form-label d-block mt-3">E-posta</label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <input
                type="email"
                className={`form-control w-100 ${
                  errors.email ? 'is-invalid' : ''
                }`}
                placeholder="E-posta Giriniz"
                {...field}
              />
            )}
          />
          {errors.email && (
            <p className="invalid-feedback">{errors.email.message}</p>
          )}
          <label className="form-label d-block mt-3">Şifre</label>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <input
                type="password"
                className={`form-control w-100 ${
                  errors.password ? 'is-invalid' : ''
                }`}
                placeholder="Şifre Giriniz"
                {...field}
              />
            )}
          />
          {errors.password && (
            <p className="invalid-feedback">{errors.password.message}</p>
          )}
          <label className="form-label d-block mt-3">Rol</label>
          <Controller
            name="role_id"
            control={control}
            render={({ field }) => (
              <select
                className={`form-control w-100 ${
                  errors.role_id ? 'is-invalid' : ''
                }`}
                {...field}>
                <option>Rol Seçiniz</option>
                {roleData &&
                  roleData?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            )}
          />
          {errors.role_id && (
            <p className="invalid-feedback">{errors.role_id.message}</p>
          )}
          <label className="form-label d-block mt-3">Şirket</label>
          <Controller
            name="company_id"
            control={control}
            render={({ field }) => (
              <select
                className={`form-control w-100 ${
                  errors.company_id ? 'is-invalid' : ''
                }`}
                {...field}>
                <option>Şirket Seçiniz</option>
                {companyData?.map((item, index) => {
                  return (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            )}
          />
          {errors.company_id && (
            <p className="invalid-feedback">{errors.company_id.message}</p>
          )}
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateUser;
