import { yupResolver } from '@hookform/resolvers/yup';
import UseRoleShow from 'context/UseShow';
import { List } from 'jsx/components';
import { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import 'react-dropzone-uploader/dist/styles.css';
import { Controller, useForm } from 'react-hook-form';
import { formatError } from 'services/AuthService';
import axiosInstance from 'services/AxiosInstance';
import swal from 'sweetalert';
import * as yup from 'yup';

const schema = yup.object().shape({
  username: yup.string().required('Username zorunludur'),
  email: yup.string().email('Email zorunludur').required('Email zorunludur'),
  password: yup.string().required('Şifre zorunludur'),
  company_id: yup.number().required('Şirket bilgisi zorunludur'),
  role_id: yup.string().required('Role zorunludur'),
  firstname: yup.string().required('İsim zorunludur'),
  surname: yup.string().required('Soyisim zorunludur'),
});

const editSchema = yup.object().shape({
  username: yup.string().required('Username zorunludur'),
  email: yup.string().email('Email zorunludur').required('Email zorunludur'),
  company_id: yup.number().required('Şirket bilgisi zorunludur'),
  role_id: yup.string().required('Role zorunludur'),
  firstname: yup.string().required('İsim zorunludur'),
  surname: yup.string().required('Soyisim zorunludur'),
});

const UserList = () => {
  const [userData, setUserData] = useState();
  const [contactModal, setContactModal] = useState();
  const [edit, setEdit] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [companyData, setCompanyData] = useState();
  const [roleData, setRoleData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [totalDataCount, setTotalDataCount] = useState(0);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(edit ? editSchema : schema),
  });

  useEffect(() => {
    getUserList();
    getCompanyList();
    getRoleList();
  }, []);

  useEffect(() => {
    getUserList();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getUserList = () => {
    axiosInstance
      .get(`user/list?limit=${rowsPerPage}&page=${page + 1}`)
      .then(res => {
        setUserData(res.data.data);
        setPageCount(res.data.page_count);
        setTotalDataCount(res.data.data?.length * res.data.page_count);
      });
  };

  const getCompanyList = () => {
    axiosInstance
      .get('company/list')
      .then(res => {
        setCompanyData(res.data.data);
      })
      .catch(err => {});
  };

  const getRoleList = () => {
    axiosInstance
      .get('role/list')
      .then(res => {
        setRoleData(res.data.data);
      })
      .catch(err => {});
  };
  const userDelete = id => {
    axiosInstance
      .delete(`user/delete?id=${id}`)
      .then(res => {
        getUserList();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const onSubmit = data => {
    axiosInstance
      .post('user/create', data)
      .then(res => {
        getUserList();
        reset();
        setContactModal(false);
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const onEditModal = id => {
    setEdit(true);
    setSelectedId(id);
    axiosInstance.get(`user/get?id=${id}`).then(res => {
      const company = companyData.filter(
        x => x?.name === res.data.data.company_name,
      );
      const role = roleData.filter(x => x.name === res.data.data.role_name);
      setValue('firstname', res.data.data.firstname);
      setValue('surname', res.data.data.surname);
      setValue('email', res.data.data.email);
      setValue('username', res.data.data.username);
      setValue('role_id', role[0]?.id);
      setValue('company_id', company[0]?.id);
      setContactModal(true);
    });
  };

  const closeModal = () => {
    reset();
    setEdit(false);
    setContactModal(false);
    setSelectedId();
  };

  const onUpdate = data => {
    data.id = selectedId;
    axiosInstance
      .put('user/update', data)
      .then(res => {
        getUserList();
        closeModal();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const tableHeaders = [
    {
      key: 'firstname',
      label: 'Ad',
    },
    {
      key: 'start_date',
      label: 'surname',
    },
    {
      key: 'email',
      label: 'E-posta Adresi',
    },
    {
      key: 'company_name',
      label: 'Şirket',
    },
    {
      key: 'role_name',
      label: 'Rol',
    },
    {
      key: 'action',
      label: '',
    },
  ];

  const renderAction = item => {
    const actionItems = [
      {
        label: 'Güncelle',
        onClick: () => onEditModal(item?.id),
        className: '',
      },
      {
        label: 'Sil',
        onClick: () => userDelete(item?.id),
        className: 'text-danger',
      },
    ];
    return <Dropdown items={actionItems} />;
  };

  const tableBody = () => {
    return userData?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item?.firstname}</td>
          <td>{item?.surname}</td>
          <td>{item?.email}</td>
          <td>{item?.company_name}</td>
          <td>{item?.role_name}</td>
          <td>{renderAction(item)}</td>
        </tr>
      );
    });
  };
  return (
    <>
      <List
        isCreate={UseRoleShow(['user_create'])}
        title="Kullanıcı Listesi"
        body={tableBody}
        addLink="/add-user"
        headers={tableHeaders}
        pageCount={totalDataCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Modal
        className="modal fade"
        id="exampleModal"
        centered
        show={contactModal}
        onHide={closeModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Kullanıcı Güncelle
            </h5>
            <button
              onClick={closeModal}
              type="button"
              className="btn-close"></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(edit ? onUpdate : onSubmit)}>
              <label className="form-label d-block mt-3">Ad</label>
              <Controller
                name="firstname"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      errors.firstname ? 'is-invalid' : ''
                    }`}
                    placeholder="Ad Giriniz"
                    {...field}
                  />
                )}
              />
              {errors.firstname && (
                <p className="invalid-feedback">{errors.firstname.message}</p>
              )}
              <label className="form-label d-block mt-3">Soyad</label>
              <Controller
                name="surname"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      errors.surname ? 'is-invalid' : ''
                    }`}
                    placeholder="Soyad Giriniz"
                    {...field}
                  />
                )}
              />
              {errors.surname && (
                <p className="invalid-feedback">{errors.surname.message}</p>
              )}
              <label className="form-label d-block mt-3">Kullanıcı Adı</label>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      errors.username ? 'is-invalid' : ''
                    }`}
                    placeholder="Kullanıcı Adı Giriniz"
                    {...field}
                  />
                )}
              />
              {errors.username && (
                <p className="invalid-feedback">{errors.username.message}</p>
              )}

              <label className="form-label d-block mt-3">E-posta Adresi</label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    type="email"
                    className={`form-control w-100 ${
                      errors.email ? 'is-invalid' : ''
                    }`}
                    placeholder="E-posta Adresi Giriniz"
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <p className="invalid-feedback">{errors.email.message}</p>
              )}
              {!edit && (
                <>
                  <label className="form-label d-block mt-3">Şifre</label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="password"
                        className={`form-control w-100 ${
                          errors.password ? 'is-invalid' : ''
                        }`}
                        placeholder="Şifre Giriniz"
                        {...field}
                      />
                    )}
                  />
                  {errors.password && (
                    <p className="invalid-feedback">
                      {errors.password.message}
                    </p>
                  )}
                </>
              )}
              <label className="form-label d-block mt-3">Rol</label>
              <Controller
                name="role_id"
                control={control}
                render={({ field }) => (
                  <select
                    className={`form-control w-100 ${
                      errors.role_id ? 'is-invalid' : ''
                    }`}
                    {...field}>
                    <option>Rol Seçiniz</option>
                    {roleData &&
                      roleData?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                  </select>
                )}
              />
              {errors.role_id && (
                <p className="invalid-feedback">{errors.role_id.message}</p>
              )}
              <label className="form-label d-block mt-3">Şirket Bilgisi</label>
              <Controller
                name="company_id"
                control={control}
                render={({ field }) => (
                  <select
                    className={`form-control w-100 ${
                      errors.company_id ? 'is-invalid' : ''
                    }`}
                    {...field}>
                    <option>Şirket Seçiniz</option>
                    {companyData?.map((item, index) => {
                      return (
                        <option key={index} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              />
              {errors.company_id && (
                <p className="invalid-feedback">{errors.company_id.message}</p>
              )}
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Kaydet
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UserList;
