import { Dropdown } from 'react-bootstrap';

import 'react-dropzone-uploader/dist/styles.css';

const DropDown = ({ items = [] }) => {
  return (
    <Dropdown className="dropdown custom-dropdown mb-0">
      <Dropdown.Toggle
        className="btn sharp btn-primary tp-btn i-false"
        data-toggle="dropdown">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="18px"
          height="18px"
          viewBox="0 0 24 24"
          version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <circle fill="#000000" cx="12" cy="5" r="2" />
            <circle fill="#000000" cx="12" cy="12" r="2" />
            <circle fill="#000000" cx="12" cy="19" r="2" />
          </g>
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
        {items?.length > 0 &&
          items?.map((item, index) => (
            <Dropdown.Item
              key={`${index}-index-dd`}
              onClick={item?.onClick}
              className={`dropdown-item ${item?.className}`}>
              {item?.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDown;
