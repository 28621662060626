import { Link } from 'react-router-dom';

import TablePagination from '@mui/material/TablePagination';
import 'react-dropzone-uploader/dist/styles.css';

const List = ({
  title = '',
  headers = [],
  addLink = false,
  body = {},
  pageCount,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  editModal = false,
  onEditModal,
  isCreate = false,
}) => {
  return (
    <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
      <div className="card">
        <div className="card-header border-0 pb-0 d-block">
          <div className="d-flex align-items-center justify-content-between">
            {!!title && <h4 className="card-title">{title}</h4>}
            {isCreate && !!addLink && (
              <div className="icon-box icon-box-sm bg-primary">
                <Link to={addLink}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z"
                      fill="white"></path>
                    <path
                      d="M16.3503 11.0251H12.9753V7.65009C12.9753 7.12509 12.5253 6.67509 12.0003 6.67509C11.4753 6.67509 11.0253 7.12509 11.0253 7.65009V11.0251H7.65029C7.12529 11.0251 6.67529 11.4751 6.67529 12.0001C6.67529 12.5251 7.12529 12.9751 7.65029 12.9751H11.0253V16.3501C11.0253 16.8751 11.4753 17.3251 12.0003 17.3251C12.5253 17.3251 12.9753 16.8751 12.9753 16.3501V12.9751H16.3503C16.8753 12.9751 17.3253 12.5251 17.3253 12.0001C17.3253 11.4751 16.8753 11.0251 16.3503 11.0251Z"
                      fill="white"></path>
                  </svg>
                </Link>
              </div>
            )}
            {isCreate && editModal && (
              <div className="icon-box icon-box-sm bg-primary">
                <Link to={'#'} onClick={onEditModal}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z"
                      fill="white"></path>
                    <path
                      d="M16.3503 11.0251H12.9753V7.65009C12.9753 7.12509 12.5253 6.67509 12.0003 6.67509C11.4753 6.67509 11.0253 7.12509 11.0253 7.65009V11.0251H7.65029C7.12529 11.0251 6.67529 11.4751 6.67529 12.0001C6.67529 12.5251 7.12529 12.9751 7.65029 12.9751H11.0253V16.3501C11.0253 16.8751 11.4753 17.3251 12.0003 17.3251C12.5253 17.3251 12.9753 16.8751 12.9753 16.3501V12.9751H16.3503C16.8753 12.9751 17.3253 12.5251 17.3253 12.0001C17.3253 11.4751 16.8753 11.0251 16.3503 11.0251Z"
                      fill="white"></path>
                  </svg>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive recentOrderTable">
            <table className="table verticle-middle table-responsive-md">
              <thead>
                <tr>
                  {headers?.length > 0 &&
                    headers?.map((item, index) => (
                      <th key={`${index}-table-th`} scope="col">
                        {item?.label || ''}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>{!!body && body()}</tbody>
            </table>
            <TablePagination
              labelRowsPerPage="Sayfa Başına"
              component="div"
              count={pageCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
