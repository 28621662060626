import { yupResolver } from '@hookform/resolvers/yup';
import UseRoleShow from 'context/UseShow';
import { List } from 'jsx/components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import 'react-dropzone-uploader/dist/styles.css';
import { Controller, useForm } from 'react-hook-form';
import { formatError } from 'services/AuthService';
import axiosInstance from 'services/AxiosInstance';
import swal from 'sweetalert';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('İsim zorunludur'),
});

const Company = () => {
  const [companyData, setCompanyData] = useState();
  const [contactModal, setContactModal] = useState();
  const [edit, setEdit] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [totalDataCount, setTotalDataCount] = useState(0);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getCompanyList();
  }, [page, rowsPerPage]);
  const getCompanyList = () => {
    axiosInstance
      .get(`company/list?limit=${rowsPerPage}&page=${page + 1}`)
      .then(res => {
        setCompanyData(res.data.data);
        setPageCount(res.data.page_count);
        setTotalDataCount(res.data.data?.length * res.data.page_count);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const companyDelete = id => {
    axiosInstance
      .delete(`company/delete?id=${id}`)
      .then(res => {
        getCompanyList();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const onSubmit = data => {
    axiosInstance
      .post('company/create', data)
      .then(res => {
        getCompanyList();
        reset();
        setContactModal(false);
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const onEditModal = id => {
    setSelectedId(id);
    axiosInstance.get(`company/get?id=${id}`).then(res => {
      setValue('name', res.data.data.name);
      setContactModal(true);
      setEdit(true);
    });
  };

  const closeModal = () => {
    reset();
    setEdit(false);
    setContactModal(false);
    setSelectedId();
  };

  const onUpdate = data => {
    data.id = selectedId;
    axiosInstance
      .put('company/update', data)
      .then(res => {
        getCompanyList();
        closeModal();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const tableHeaders = [
    {
      key: 'title',
      label: 'Şirket Adı',
    },
    {
      key: 'created_at',
      label: 'Başlangıç Tarihi',
    },
    {
      key: 'updated_at',
      label: 'Son Güncelleme Tarihi',
    },
  ];

  const renderAction = item => {
    const actionItems = [
      {
        label: 'Güncelle',
        onClick: () => onEditModal(item?.id),
        className: '',
      },
      {
        label: 'Sil',
        onClick: () => companyDelete(item?.id),
        className: 'text-danger',
      },
    ];
    return <Dropdown items={actionItems} />;
  };

  const tableBody = () => {
    return companyData?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item?.name}</td>
          <td>{moment(item?.created_at).format('DD-MM-YYYY hh:mm')}</td>
          <td>{moment(item?.updated_at).format('DD-MM-YYYY hh:mm')}</td>
          <td>{renderAction(item)}</td>
        </tr>
      );
    });
  };

  const openCreateModal = () => {
    setContactModal(true);
  };
  return (
    <>
      <List
        isCreate={UseRoleShow(['company_create'])}
        title="Şirket Listesi"
        body={tableBody}
        editModal
        onEditModal={openCreateModal}
        headers={tableHeaders}
        pageCount={totalDataCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Modal
        className="modal fade"
        id="exampleModal"
        centered
        show={contactModal}
        onHide={closeModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Yeni Şirket Oluştur
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => closeModal()}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(edit ? onUpdate : onSubmit)}>
              <label className="form-label d-block">Şirket Adı</label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      errors.name ? 'is-invalid' : ''
                    }`}
                    placeholder="Şirket Adı Giriniz"
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <p className="invalid-feedback">{errors.name.message}</p>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => closeModal()}>
                  Kapat
                </button>
                <button type="submit" className="btn btn-primary">
                  Kaydet
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Company;
