import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'services/AxiosInstance';
import swal from 'sweetalert';
import { campaignSchema } from './RoleSchema';
const AddRole = () => {
  const [selectedOptions, setSelectedOptions] = useState([]); // Seçilen seçeneklerin dizisi
  const [data, setData] = useState();
  useEffect(() => {
    getPermission();
  }, []);

  const getPermission = () => {
    axiosInstance.get('permission/list').then(response => {
      const _data = response.data.data.filter(x => x.category !== 'permission');
      const transformedData = _data.reduce((result, item) => {
        const { category, name, id } = item;
        if (!result.find(obj => obj.category === category)) {
          result.push({
            category,
            delete: '',
            read: '',
            update: '',
            write: '',
          });
        }
        const categoryObj = result.find(obj => obj.category === category);
        categoryObj[name.toLowerCase()] = id;

        return result;
      }, []);
      setData(transformedData);
    });
  };
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(campaignSchema),
  });

  const navigate = useNavigate();
  const onSubmit = data => {
    if (selectedOptions.length < 1) {
      swal('Oops', 'Rol için yetki Seçiniz', 'error');
    } else {
      data.permissions = selectedOptions;
      axiosInstance
        .post('role/create', data)
        .then(res => {
          reset();
          navigate('/role');
          setSelectedOptions([]);
        })
        .catch(error => {});
    }
  };

  const tableHeaders = [
    {
      label: 'Yetki Adı',
    },
    {
      label: 'Write',
    },
    {
      label: 'Read',
    },
    {
      label: 'Update',
    },
    {
      label: 'Delete',
    },
  ];

  const handleOptionChange = event => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    }
  };

  return (
    <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
      <div className="card p-5">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="card-title">Rol Oluştur</h4>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="form-label d-block mt-3">Rol Adı</label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.name ? 'is-invalid' : ''
                }`}
                placeholder="Rol Adı"
                {...field}
              />
            )}
          />
          {errors.name && (
            <p className="invalid-feedback">{errors.name.message}</p>
          )}
          <label className="form-label d-block mt-3">Rol Açıklaması</label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control w-100 ${
                  errors.description ? 'is-invalid' : ''
                }`}
                placeholder="Rol Açıklaması"
                {...field}
              />
            )}
          />
          {errors.description && (
            <p className="invalid-feedback">{errors.description.message}</p>
          )}
          <table className="table">
            <thead>
              <tr>
                {tableHeaders.map((item, index) => (
                  <th>{item?.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <p>{item?.category.toUpperCase()}</p>
                      </td>
                      <td>
                        <input
                          checked={selectedOptions.includes(item.write)}
                          type="checkbox"
                          name={`write-${item?.category}`}
                          value={item?.write}
                          id={item?.write}
                          onChange={handleOptionChange}
                        />
                      </td>
                      <td>
                        <input
                          checked={selectedOptions.includes(item.read)}
                          type="checkbox"
                          name={`read-${item?.category}`}
                          value={item?.read}
                          onChange={handleOptionChange}
                          id={item?.read}
                        />
                      </td>
                      <td>
                        <input
                          checked={selectedOptions.includes(item.update)}
                          type="checkbox"
                          name={`read-${item?.category}`}
                          value={item?.update}
                          onChange={handleOptionChange}
                          id={item?.update}
                        />
                      </td>
                      <td>
                        <input
                          checked={selectedOptions.includes(item.delete)}
                          type="checkbox"
                          name={`read-${item?.category}`}
                          value={item?.delete}
                          onChange={handleOptionChange}
                          id={item?.delete}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate('/role')}>
              Kapat
            </button>
            <button type="submit" className="btn btn-primary">
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddRole;
