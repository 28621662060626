import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import avatar from "../../../images/avatar/1.jpg";
//import { Dropdown } from "react-bootstrap";
//import LogoutPage from './Logout';
//import RightSideBar from './RightSideBar';

import LogoutPage from './Logout';

import avatar from '../../../images/avatar/1.jpg';

const NotificationBlog = ({ classChange }) => {
  return (
    <>
      <li>
        <div className="timeline-panel">
          <div className="media me-2">
            <img alt="images" width={50} src={avatar} />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Dr sultads Send you Photo</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>KG</div>
          <div className="media-body">
            <h6 className="mb-1">Resport created successfully</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>
            <i className="fa fa-home" />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Reminder : Treatment Time!</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
    </>
  );
};

const Header = ({ onNote }) => {
  const [rightSelect, setRightSelect] = useState('Eng');
  //For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const users = JSON.parse(localStorage.getItem('userDetails'));
  //const [searchBut, setSearchBut] = useState(false);
  var path = window.location.pathname.split('/');
  const pages = [
    { path: '', name: 'Anasayfa' },
    { path: 'dashboard', name: 'Anasayfa' },
    { path: 'add-user', name: 'Kullanıcı Ekle' },
    { path: 'user', name: 'Kullanıcı Listesi' },
    { path: 'role', name: 'Rol Listesi' },
    { path: 'add-role', name: 'Rol Ekle' },
    { path: 'edit-role', name: 'Rol Güncelle' },
    { path: 'profile', name: 'Profil' },
    { path: 'campaign', name: 'Kampanya Listesi' },
    { path: 'add-campaign', name: 'Kampanya Ekle' },
    { path: 'edit-campaign', name: 'Kampanya Güncelle' },
    { path: 'campaign-accept', name: 'Onay Listesi' },
    { path: 'company', name: 'Şirket Listesi' },
    { path: 'coupon', name: 'Kuponlar Listesi' },
  ];

  var finalName =
    (path?.length === 2 &&
      pages?.find(item => item?.path === path?.[1])?.name) ||
    '';

  return (
    <div className={`header ${headerFix ? 'is-fixed' : ''}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: 'capitalize' }}>
                {finalName}
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="dz-side-menu">
                <div />
                <ul>
                  <Dropdown
                    as="li"
                    className="nav-item dropdown header-profile">
                    <Dropdown.Toggle
                      variant=""
                      as="a"
                      className="nav-link i-false c-pointer bg-primary rounded-circle d-flex align-items-center">
                      <div className="shortName">
                        {`${users?.username?.charAt(0)}`}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="right"
                      className="dropdown-menu dropdown-menu-end">
                      <Link to="/profile" className="dropdown-item ai-icon">
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary me-1"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ms-2">Profile </span>
                      </Link>
                      {/*<Link to="/email-inbox" className="dropdown-item ai-icon">
                        <svg
                          id="icon-inbox"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-success me-1"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                          <polyline points="22,6 12,13 2,6" />
                        </svg>
                        <span className="ms-2">Inbox </span>
</Link>*/}
                      <LogoutPage />
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
