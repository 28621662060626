let direction = 'ltr';

export const dezThemeSet = [
  {
    /* Default */ typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    headerBg: 'color_1',
    navheaderBg: 'color_4',
    sidebarBg: 'color_4',
    sidebarStyle: 'full',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'full',
    primary: 'color_1',
    direction: direction,
  },
  {
    /* Demo Theme 1 */ typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    headerBg: 'color_1',
    navheaderBg: 'color_4',
    sidebarBg: 'color_4',
    sidebarStyle: 'full',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'full',
    primary: 'color_1',
    //secondary:"color_10",
    direction: direction,
  },
  {
    /* Demo Theme 2 */ typography: 'poppins',
    version: 'light',
    layout: 'horizontal',
    navheaderBg: 'color_2',
    headerBg: 'color_2',
    sidebarStyle: 'full',
    sidebarBg: 'color_1',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_2',
    secondary: 'color_1',
    direction: direction,
  },
  {
    /* Demo Theme 3 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_8',
    headerBg: 'color_1',
    sidebarStyle: 'full',
    sidebarBg: 'color_1',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_8',
    secondary: 'color_1',
    direction: direction,
  },
  {
    /* Demo Theme 4 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_5',
    headerBg: 'color_5',
    sidebarStyle: 'full',
    sidebarBg: 'color_1',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_5',
    secondary: 'color_1',
    direction: direction,
  },
  {
    /* Demo Theme 5 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_11',
    headerBg: 'color_1',
    sidebarStyle: 'full',
    sidebarBg: 'color_11',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_11',
    secondary: 'color_1',
    direction: direction,
  },
  {
    /* Demo Theme 6 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_1',
    headerBg: 'color_1',
    sidebarStyle: 'full',
    sidebarBg: 'color_1',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_5',
    secondary: 'color_1',
    direction: direction,
  },
  {
    /* Demo Theme 7 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_9',
    headerBg: 'color_1',
    sidebarStyle: 'full',
    sidebarBg: 'color_9',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_9',
    secondary: 'color_1',
    direction: direction,
  },
  {
    /* Demo Theme 8 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_10',
    headerBg: 'color_1',
    sidebarStyle: 'mini',
    sidebarBg: 'color_10',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_10',
    secondary: 'color_1',
    direction: direction,
  },
];
