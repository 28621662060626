import { useEffect, useState } from 'react';

const UseRoleShow = permissions => {
  const user = JSON.parse(localStorage.getItem('userDetails'));
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const perm =
      permissions.find(role => user.permissions.includes(role)) ||
      permissions[0] === 'public'
        ? true
        : false;
    setHasPermission(perm);
  }, [permissions]);

  return hasPermission;
};

export default UseRoleShow;
