import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import swal from 'sweetalert';

import 'react-dropzone-uploader/dist/styles.css';

import UseRoleShow from 'context/UseShow';
import { Dropdown, List } from 'jsx/components';
import { formatError } from 'services/AuthService';
import axiosInstance from 'services/AxiosInstance';

const CampaingList = () => {
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    getList();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getStatusName = status => {
    switch (status) {
      case 'pending':
        return 'Onay Bekliyor';
      case 'approved':
        return 'Onaylandı';
      case 'declined':
        return 'İptal Edildi';
      default:
        return '';
    }
  };

  const getList = () => {
    axiosInstance
      .get(`campaing/list?limit=${rowsPerPage}&page=${page + 1}`)
      .then(res => {
        const resData = res.data.data?.map(item => {
          return {
            ...item,
            statusName: getStatusName(item?.status),
          };
        });
        setPageCount(res.data.page_count);
        setData(resData);
        setTotalDataCount(res.data.data?.length * res.data.page_count);
      })
      .catch(err => {});
  };

  const navigate = useNavigate();

  const campaingDelete = id => {
    axiosInstance
      .delete(`campaing/delete?id=${id}`)
      .then(res => {
        getList();
        swal('Success', `${res.data.message}`, 'success');
      })
      .catch(error => {
        formatError(error.response.data);
      });
  };

  const tableHeaders = [
    {
      key: 'title',
      label: 'Kampanya Adı',
    },
    {
      key: 'start_date',
      label: 'Başlangıç Tarihi',
    },
    {
      key: 'end_date',
      label: 'Bitiş Tarihi',
    },
    {
      key: 'max_use',
      label: 'Toplam Kod Sayısı',
    },
    {
      key: 'creator',
      label: 'Oluşturan',
    },
    {
      key: 'status',
      label: 'Durumu',
    },
    {
      key: 'action',
      label: '',
    },
  ];

  const isUpdate = UseRoleShow(['campaing_update']);
  const isDelete = UseRoleShow(['campaing_delete']);
  const renderAction = item => {
    const actionItems = [
      isUpdate && {
        label: 'Güncelle',
        onClick: () =>
          navigate('/edit-campaing', {
            state: { id: item?.id, isAccept: false },
          }),
        className: '',
      },
      isDelete && {
        label: 'Sil',
        onClick: () => campaingDelete(item?.id),
        className: 'text-danger',
      },
    ];
    return <Dropdown items={actionItems} />;
  };

  const tableBody = () => {
    return data?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item?.title}</td>
          <td>{moment(item?.start_date).format('DD-MM-YYYY hh:mm')}</td>
          <td>{moment(item?.end_date).format('DD-MM-YYYY hh:mm')}</td>
          <td>{item?.max_use}</td>
          <td>{item?.creator}</td>
          <td>
            {item?.status === 'pending' && (
              <span className="badge badge-rounded badge-warning">
                {item?.statusName}
              </span>
            )}
            {item?.status === 'approved' && (
              <span className="badge badge-success badge-rounded">
                {item?.statusName}
              </span>
            )}
            {item?.status === 'declined' && (
              <span className="badge badge-danger badge-rounded">
                {item?.statusName}
              </span>
            )}
          </td>
          {isUpdate || isDelete ? <td>{renderAction(item)}</td> : null}
        </tr>
      );
    });
  };

  return (
    <List
      isCreate={UseRoleShow(['campaing_create'])}
      title="Kampanya Listesi"
      body={tableBody}
      addLink="/add-campaign"
      headers={tableHeaders}
      pageCount={totalDataCount}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default CampaingList;
