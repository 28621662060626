import { Navigate, Outlet, useLocation } from 'react-router-dom';

const WithPermission = ({ permissions }) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('userDetails'));
  return permissions.find(role => user.permissions.includes(role)) ||
    permissions[0] === 'public' ? (
    <Outlet />
  ) : !user || !user.permissions ? (
    <Navigate to="/register" state={{ from: location }} replace />
  ) : (
    <Navigate to="/page-error-403" state={{ from: location }} replace />
  );
};

export default WithPermission;
